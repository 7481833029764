import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import ContactUs from '../components/ContactUs'
import routes from '../routes'

const useStyles = makeStyles((theme) => ({
  main: {
    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,
    marginBottom: 60,

    [theme.breakpoints.up('desktop')]: {
      paddingLeft: theme.gutters.desktop,
      paddingRight: theme.gutters.desktop,
    },
  },
  header: {
    color: '#2FA43B',
  },
  bold: {
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    color: '#4377bb',
  },
  center: {
    textAlign: 'center',
  },
  hero: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    height: 350,
    marginTop: -20,
    top: -48, // Account for the header bar height
    paddingTop: 48,

    [theme.breakpoints.up('tablet')]: {
      marginTop: -15,
    },

    [theme.breakpoints.up('desktop')]: {
      marginTop: 24,
      height: 450,
    },
  },
  titleContainer: {
    width: '100%',
    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 150,

    [theme.breakpoints.up('tablet')]: {
      height: 150,
    },

    [theme.breakpoints.up('desktop')]: {
      height: 250,
    },
  },
  block: {
    display: 'block',
  },
}))
export default function Terms() {
  const classes = useStyles()

  return (
    <>
      <div className={classes.hero}>
        <div className={classes.titleContainer}>
          <Typography component='div' variant='h1' style={{ color: 'white' }}>
            Terms & Conditions
          </Typography>
        </div>
      </div>
      <main className={classes.main}>
        <Typography
          variant='h6'
          component='div'
          className={`${classes.center} ${classes.bold}`}
        >
          CYCURID TERMS AND CONDITIONS OF USE
        </Typography>
        <br />
        <Typography
          variant='body1'
          component='div'
          className={`${classes.center} ${classes.bold}`}
        >
          These Terms of Use were last updated:{' '}
          <span className={classes.bold}> January 1th, 2023</span>.
        </Typography>
        <br />
        <Typography
          variant='body1'
          component='div'
          className={`${classes.center} ${classes.bold}`}
        >
          These terms and conditions of use (the “Terms”) apply to your access
          to and use of any materials, content or services provided through or
          accessible on this website or app (the “Site”), owned and operated by
          CycurID Technologies Ltd. or CycurID Technologies USA Ltd, and/or any
          of its corporate affiliates (“CycurID™”, “imme™”, “the Company”, “we”,
          “us”, “our”). By accessing and using the Site, including the currency
          management software available for download on the Site (the “Software”
          and together with the Site, the “Services”), you accept and agree to
          be bound by these Terms, and all applicable laws and regulations.
          <br />
          <br />
          PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN IMPORTANT
          INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS,
          INCLUDING, BUT NOT LIMITED TO, VARIOUS LIMITATIONS, EXCLUSIONS AND
          INDEMNITIES.
          <br />
          <br />
          Your use of the Services is subject to these Terms. If you are not
          willing to be bound by each and every term and condition, or if any
          representation made by you herein is not true, you may not use, and
          must immediately cease accessing the Services.
        </Typography>
        <br />
        <Typography
          variant='h6'
          component='div'
          className={`${classes.center} ${classes.bold}`}
        >
          LEGAL INFORMATION & NOTICES
        </Typography>
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          UPDATES TO TERMS
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          We reserve the right to amend these Terms at any time without notice
          to you, but we will use reasonable efforts to publish each amendment
          before it becomes effective. We will publish the latest, fully-amended
          version of these Terms on the Site. You are responsible for regularly
          reviewing the Site to obtain timely notice of such amendments. If any
          amendment is unacceptable to you, you may terminate your agreement to
          these Terms as set out above. If you continue to use the Services
          after the effective date of each amendment, you will be conclusively
          deemed to have accepted such amended version of these Terms.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Eligibility
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          To be eligible to use any of the CycurID’s and/or imme’s Services, you
          must be at least 18 years old, have capacity to enter into legally
          binding contracts and reside in a country in which the relevant
          Services are accessible. Services are currently available in Canada
          and the Continental United States.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          License to Use
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          Subject to these Terms, we grant you permission to access the Site as
          a personal, non-exclusive, non-transferable, limited license to use
          the Site for transitory, non-commercial purposes, provided that with
          respect to any materials obtained from the Site, including the
          Software, you may not: <br />
          <br />
          &bull; Modify, translate, copy, reproduce or otherwise create derivate
          works or improvements, whether or not patentable, of the Site, the
          Software, or any such materials, except as explicitly provided
          <br />
          &bull; Distribute or transmit the materials except as explicitly
          permitted
          <br />
          &bull; Publicly display, publish or perform the materials (for any
          purpose, commercial or non-commercial)
          <br />
          &bull; Create derivate works from, transfer, or sell any materials
          <br />
          &bull; Attempt to decompile, disassemble, decode, reverse engineer or
          otherwise attempt to derive or gain access to the source code of any
          software or database contained in or accessed through the Services; or
          <br />
          &bull; Remove, delete, alter or obscure any copyright, trademark,
          patent or other intellectual property or proprietary rights notice
          from the Services, including any copy thereof or other proprietary
          notations,
          <br />
          <br />
          without our prior, written consent. All rights not expressly granted
          to you are reserved by us and, if applicable, our licensors. For
          greater clarity, you are permitted to refer to the Services through
          email, social media, or other similar methods, provided that you do
          not imply any affiliation between you and us, or you and the Services,
          or portray us or the Services in a false, misleading, derogatory or
          otherwise defamatory manner. CycurID&trade; is not a bank or financial
          institution and does not provide investment, financial, or consulting
          services to users of the Services.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Responsibility for the Software
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          As the Software will be locally installed on your device, you are
          responsible for the security of the device on which the Software is
          installed, including protection of such device from all malware.
          Subject to these Terms, we grant you a personal, non-exclusive,
          non-transferable, limited license to use the Software on computers and
          devices you own or control for your personal or internal business
          purposes. CycurID&trade; is not responsible for any loss or damage
          arising from your failure to keep the device on which the Software is
          installed secure, safe and free of any malware, including any loss of
          funds or lockout from accounts as a result of such malware. It is your
          sole responsibility to take all reasonable precautions to secure and
          backup your copy of the Software and any information stored in it.
          CycurID&trade; cannot recover passwords or unlock account information
          stored on the Software in any circumstance.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Privacy Policy
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          You acknowledge that you have read the{' '}
          <Link to={routes.PRIVACY}>Privacy Policy</Link> as it may be updated
          from time to time (the “
          <span className={classes.bold}>Privacy Policy</span>”), and hereby
          consent to the collection, use and disclosure by us and our agents of
          your personal information (whether previously collected or to be
          collected) for the purposes identified therein. You also consent to
          our use of such personal information in accordance with applicable
          terms and conditions contained in these Terms and such{' '}
          <Link to={routes.PRIVACY}>Privacy Policy</Link>, which is incorporated
          herein by reference and forms an integral part hereof.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Your Account
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          Your use of the Services may require an account identifying you as a
          user (an “<span className={classes.bold}>Account</span>”). When you
          create an Account, you will be asked to provide certain information
          which will be subject to our{' '}
          <Link to={routes.PRIVACY}>Privacy Policy</Link>.
          <br />
          <br />
          You agree to provide true, current, accurate and complete customer
          information as requested by us or our agents from time to time and you
          agree to promptly notify us of any changes to this information as
          required to keep such information held by us current, complete and
          accurate. From time to time there maybe instances whereby the Company
          may require you to submit additional information about yourself and
          provide additional records, (“
          <span className={classes.bold}>Enhanced Due Diligence</span>“). Based
          on our Enhanced Due Diligence, we reserve the right to decline your
          account opening request and/or close an existing account.
          <br />
          <br />
          When you create your imme Account, a unique device specific digital
          identity is created using your biometrics. Each time this unique
          device specific digital identity or identification is accessed, you
          will be deemed be authorized to access and use the Services in a
          manner consistent with these Terms and we shall have no obligation to
          investigate the authorization or source of any such access or use of
          the Services.
          <br />
          <br />
          In connection with your Account, you are solely responsible for (i)
          your Account and the maintenance, confidentiality and security of your
          Account and (ii) any and all activities that occur under your Account,
          including all activities of any persons who gain access to your
          account, with or without your permission. You must not register for an
          Account on behalf of any individual other than yourself or register
          for an account on behalf of any group or non-individual entity unless
          you are duly authorized to do so by such group or entity.
          <br />
          <br />
          You must not register for an Account on behalf of any individual other
          than yourself or register for an account on behalf of any group or
          non-individual entity unless you are duly authorized to do so by such
          group or entity.
          <br />
          <br />
          You agree to immediately notify us of (i) any unauthorized use of your
          Account, any service provided through your Account or (ii) any other
          breach of security with respect to your Account or any service
          provided through it, and (iii) you agree to provide assistance to us,
          as requested, to stop or remedy any breach of security related to your
          Account.
          <br />
          <br />
          You agree to immediately notify us of (i) any unauthorized use of your
          Account, any service provided through your Account or (ii) any other
          breach of security with respect to your Account or any service
          provided through it, and (iii) you agree to provide assistance to us,
          as requested, to stop or remedy any breach of security related to your
          Account.
          <br />
          <br />
          You agree we may update your information with information available to
          us. All such personal information is subject to the{' '}
          <Link to={routes.PRIVACY}>Privacy Policy</Link>.
          <br />
          <br />
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Fees and Payment
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          <span className={classes.bold}>End User Fees</span> - The use of
          certain Third Party Services may be subject to transaction fees and/or
          other fees charged by such Third Party Services. On top of such fees,
          the Company reserves the right to charge you fees for the secured and
          facilitated access provided by the Company to use such Third Party
          Services. As an example, Users of the imme wallet will be charged
          0.0015% on all transaction of funds in and out of their wallet. The
          Company Fees may be charged directly to the User at the time of
          transaction or indirectly if already included in the Third Party Fees
          and paid by the Third Party to the Company on behalf of the User. In
          such event, The Company will fully display all applicable fees and any
          applicable taxes.
          <br />
          <br />
          <span className={classes.bold}>Merchant/Company Fees</span> – Your use
          of the Services is subject to you paying CycurID the fees applicable
          to the Services plan selected and/or used by you ("
          <span className={classes.bold}>Subscription Fee</span>"). The
          Subscription Fees, permitted usage, and available features for each
          Services plan are listed at{' '}
          {/* <a
            href='https://cycurid.com/pricing'
            className={classes.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            https://cycurid.com/pricing/
          </a>{' '} */}
          (the "<span className={classes.bold}>Pricing</span>").
          <br />
          <br />
          You will pay all fees in United States Dollars per the payment terms
          on an Order or otherwise agreed at the time of purchase. The amount of
          fees payable by you shall vary depending upon the number of Users and
          the period for which you are authorized to Use the Services ("
          <span className={classes.bold}>Subscription Term</span>"). Payment
          obligations are non-cancelable and, except as expressly stated in
          these Terms, fees paid are non- refundable. In making payment, you
          acknowledge and agree that you are not relying on being able to Use
          the Services beyond the Subscription Term or on the availability of
          any future enhancements or upgrades. If you fail to pay any fees on
          time, CycurID reserves the right, in addition to taking any other
          action at law or equity, to (i) charge interest on past due amounts at
          1.0% per month or the highest interest rate allowed by law, whichever
          is less, and to charge all expenses of recovery, and (ii) suspend or
          terminate the applicable Services.
          <br />
          <br />
          Except as otherwise specified in an Order, all subscriptions will
          automatically renew for periods equal to your initial Subscription
          Term (and you will be charged at the then-current rates set forth in
          the Pricing) unless you cancel auto-renewal of your subscription
          through your Account at least thirty (30) days prior to the end of the
          then-current Subscription Term. If you cancel auto-renewal, your
          subscription will terminate at the end of then-current billing cycle,
          or immediately if you so choose, however you will not be entitled to
          any credits or refunds for amounts accrued or paid prior to such
          termination.
          <br />
          <br />
          <span className={classes.bold}>Taxes</span> - Our fees are exclusive
          of all taxes that are payable in respect of the Services or its Use in
          the jurisdiction in which the payment is either made or received ("
          <span className={classes.bold}>Taxes</span>"). To the extent that any
          Taxes may be payable, you must pay CycurID the amount of such Taxes in
          addition to any Subscription Fees owed by you hereunder. Any
          applicable tax-exempt certificates must be provided to CycurID who
          will make reasonable efforts to provide you with such invoicing
          documents as may enable you to seek an applicable refund or credit for
          the amount of Taxes so paid from any relevant revenue authority.
          <br />
          <br />
          Transactions or operations in Crypto Assets, or more generally Crypto
          Assets events, including but not limited to exchanges, air-drops,
          forks, and gains arising from staking, may be considered tax events
          according to the legislations law under which you are subject to
          taxation. These rules may be unclear or subject to change, and you are
          therefore encouraged to consult your own tax or accounting adviser
          before engaging into Crypto Assets activities.
          <br />
          <br />
          <span className={classes.bold}>Payment by Credit Card</span> – When
          you purchase a subscription ("
          <span className={classes.bold}>Purchase</span>"), you expressly
          authorize CycurID (or our third party payment processor) to charge you
          the Subscription Fee (at the then-current Pricing) and Taxes, as
          follows: (i) monthly in advance for monthly plans and (ii) annually in
          advance for annual plans. Subscription Fees are subject to change,
          although CycurID will notify you in advance of any such change.
          <br />
          <br />
          CycurID may ask you to supply additional information relevant to your
          Purchase, including your credit card number and expiration date, and
          your billing address (such information, "
          <span className={classes.bold}>Payment Information</span>"). You
          represent and warrant that you have the legal right to use all payment
          method(s) represented by any such Payment Information. When you
          initiate a Purchase, you authorize CycurID to provide your Payment
          Information to our third party service providers so CycurID can
          complete your Purchase and to charge your payment method for the type
          of Purchase you have selected (plus any Taxes and other applicable
          charges).
          <br />
          <br />
          <span className={classes.bold}>
            Authorization for Recurring Payments
          </span>{' '}
          - By agreeing to these Terms and purchasing a subscription, you
          acknowledge that your subscription has recurring payment features and
          you accept responsibility for all recurring payment obligations prior
          to cancellation or termination of your subscription by you or CycurID.
          CycurID (or our third party payment processor) will automatically
          charge you in accordance with your Subscription Term (e.g., each month
          or year), on the calendar day corresponding to the commencement of
          your Subscription Term, using the Payment Information you have
          provided. In the event your Subscription Term begins on a day not
          contained in a given month, your payment method will be charged on
          such other day as we deem appropriate. For example, if you started a
          monthly subscription on January 31st, your next payment date is likely
          to be February 28th, and your payment method would be billed on that
          date. Your subscription continues until cancelled by you or CycurID
          terminates your access to or use of the Services or the subscription
          in accordance with these Terms. You will pay all fees in United States
          Dollars per the payment terms on an Order or as otherwise agreed at
          the time of purchase.
          <br />
          <br />
          <span className={classes.bold}>Chargebacks</span> - CycurID reserves
          the right to pursue any financial losses suffered due to you filing a
          chargeback procedure with your bank. These can include administration
          fees levied by the card acquirer and card schemes as well as the
          monetary value of the cryptocurrencies ordered / purchased. Further,
          if CycurID deems you incurred have excessive chargebacks, CycurID
          reserves the right to close your account.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Payment Method - Specific Terms
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          <span className={classes.bold}>ACH Payments (where applicable)</span>{' '}
          - You agree that you will provide CycurID sufficient information to
          satisfy the requirements of the Nacha Operating Rules to create
          instructions to make or receive a payment in the ACH network. CycurID
          has established cutoff times for receipt of payment instructions and
          you understand that CycurID cannot and does not guarantee any specific
          time in which ACH transactions initiated through your CycurID Account
          will be completed. For questions regarding any specific transaction or
          its anticipated timing, please contact the CycurID at:{' '}
          <a href='mailto:support@cycurid.com'>support@cycurid.com</a>
          <br />
          <br />
          <span className={classes.bold}>
            Real Time Payments (where applicable)
          </span>{' '}
          - If the CycurID client who provides you access to your CycurID
          Account or the CycurID-Enabled Services offers Real Time Payments (“
          <span className={classes.bold}>RTP</span>”) as an eligible payment
          method, these terms apply to your use of RTP. The RTP Network is owned
          and operated by The Clearing House. RTP transfers are made by
          CycurID’s Financial Institution Partner(s). By submitting an RTP
          payment instruction, you agree that we may share your information with
          our Financial Institution Partner and that you have read and you
          accept any and all Privacy Notice’s. You shall transmit to CycurID all
          information necessary to effectuate payment in accordance with the RTP
          Rules established by The Clearing House. You represent and warrant
          that (i) each RTP instruction you submit is made in accordance with
          the RTP Rules; (ii) as Sender of such RTP transaction, you authorize
          our Financial Institution Partners to initiate such payment and to
          debit your Linked Payment Account and send a credit to the Receiver’s
          account in the amount set out in your instruction; (iii) your RTP
          transaction is initiated in connection with a separate agreement with
          the Receiver; and (iv) such RTP transaction does not violate
          applicable law. You agree that you shall enter into an agreement with
          each of the Receivers prior to sending CycurID an RTP transaction in
          which they authorize you to send such RTP transaction. Further, you
          agree that you waive, release, and discharge all rights or claims
          against our Financial Institution Partners in connection with the RTP
          services. You agree to retain records related to your RTP transactions
          for 6 years following the date you send such RTP instruction to
          CycurID and to provide such data to CycurID upon request. Without
          limiting the generality of the foregoing, you may not send RTP
          transactions (y) that exceed the limits provided for in the RTP Rules
          or any other applicable transaction limits expressly imposed on your
          CycurID Account or to any account not located within the United
          States.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Cryptocurrency and Wallet
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          <span className={classes.bold}>DISCLAIMER:</span> THE RISK OF LOSS IN
          TRADING OR HOLDING BITCOIN OR ANY OTHER CRYPTOCURRENCY OR FINANCIAL
          INVESTMENT CAN BE SUBSTANTIAL.
          <br />
          <span className={classes.bold}> PLEASE NOTE:</span> THE PRICE OR VALUE
          OF BITCOIN OR ANY OTHER CRYPTOCURRENCY OR FINANCIAL INVESTMENT CAN
          CHANGE RAPIDLY, DECREASE, AND POTENTIALLY EVEN FALL TO ZERO.
          <br />
          <br />
          THEREFORE, CYCURID, NOR ANY ITS SUBSIDIARIES, OFFICERS, DIRECTORS,
          EMPLOYEES OR OTHERWISE DO NOT FACILITATE OR PROVIDE TRADING OR
          INVESTMENT OR BROKERAGE ACCOUNTS OR FACILITIES NOR PROVIDES INVESTMENT
          OR ANY OTHER FINANCIAL ADVICE AND AS SUCH, SHALL NOT BE LIABLE FOR ANY
          LOSSES INCURRED. USERS ACKNOWLEDGES THEY ARE SOLELY RESPONSIBLE FOR
          ALL LEGAL RISKS ASSOCIATED WITH CRYPTOCURRENCY TRANSACTIONS AND FOR
          ANY LOSSES RELATED TO THE SALE, PURCHASE, OR EXCHANGE OF DIGITAL
          ASSETS.
          <br />
          <br />
          It is the users’ responsibility to know the regulatory requirements
          concerning transactions with cryptocurrency in users’ jurisdiction
          before using the Services. If such legislation or regulations are
          amended and cause an interruption of the Cryptocurrencies Services,
          CycurID, its subsidiaries, officers, directors, employees or otherwise
          shall not be liable for losses incurred by the Merchant, or Merchant’s
          Customers.
          <br />
          <br />
          Users understand that some of the technology supported or made
          available through the Services may be new, untested and not provided
          by the Company and therefore outside of the Company’s control.
          Cryptocurrency transactions are facilitated by Third Party Service
          providers recorded on blockchain networks. Such networks are
          decentralized peer-to-peer networks run by independent third parties,
          which the Company does not own, control or operate and therefore
          cannot and does not ensure that the transactions users broadcast on
          the Services will be confirmed and processed. Users acknowledge the
          Company does not store, send, or receive Crypto Assets on users’
          behalf and users agrees that transactions may fail, or may be
          substantially delayed by the underlying blockchain networks.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Updates
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          We may, from time to time, in our sole discretion and without notice,
          develop and provide Site and Software updates, which may include
          upgrades, bug fixes, patches, other error corrections, and/or new
          features (collectively, including related documentation, “
          <span className={classes.bold}>Updates</span>”). Updates may also
          modify or delete in their entirety certain features and functionality.
          You agree that we have no obligation to provide any Updates or to
          continue to provide or enable any features or functionality. Based on
          your device settings, when your device is connected to the internet
          either:
          <br />
          <br />
          (a) the Services will automatically download and install all available
          Updates; or
          <br />
          <br />
          (b) you may receive notice of or be prompted to download and install
          available Updates. You shall promptly download and install all Updates
          and acknowledge and agree that the Services or portions thereof may
          not properly operate should you fail to do so. You further agree that
          all Updates will be deemed part of the Services, as appropriate, and
          be subject to all terms and conditions of the Terms.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Export Regulations
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          The Services may be subject to local and international export and
          re-export control laws and sanctions regulations. You shall not,
          directly or indirectly, export, re-export, or release the Services to,
          or make the Services accessible from, any jurisdiction or country to
          which export, re-export, or release is prohibited by law, rule, or
          regulation. You shall comply with all applicable federal laws,
          regulations, and rules, and complete all required undertakings
          (including obtaining any necessary export license or other
          governmental approval), prior to exporting, re-exporting, releasing,
          or otherwise making the Services available outside of Canada.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Proprietary Rights
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          In these Terms, “<span className={classes.bold}>Content</span>” means
          all materials and content, including designs, editorial, text,
          graphics, audiovisual materials, multimedia elements, photographs,
          videos, music, sound recordings, reports, documents, software,
          information, formulae, patterns, data and any other work with respect
          to the Services and any and all integrations with our web portal and
          that site’s entire contents, features and functionality (including but
          not limited to all information, software, text, displays, images, and
          the design, selection and arrangement thereof).
          <br />
          <br />
          <span className={classes.bold}>Our Content</span> – Except where
          expressly stated otherwise, all right, title and interest in, to and
          associated with the Services and all other Content, source code,
          processes, designs, technologies, URLs, domain names, marks and logos
          (collectively, “<span className={classes.bold}>Our Content</span>”) is
          fully vested in us, our licensors or our suppliers and is protected by
          Canadian and international copyrights, trademarks, patents, trade
          secrets and other intellectual property or other proprietary rights
          and laws. You agree that Our Content is licensed subject to these
          Terms, including the disclaimers and limitations of liability herein.
          Nothing in these Terms or your use of the Services grants you any
          right, title or interest in, to or associated with Our Content except
          the limited right to use Our Content as set out herein. You may not
          modify, copy, record, publish, transmit, distribute, participate in
          the transfer or sale, create derivative works, or in any way exploit
          any of the content, in whole or in part without our written consent.
          <br />
          <br />
          <span className={classes.bold}>Third Party Content</span> – Content
          accessed or available through the Services or the internet may be
          owned by parties other than you or us (collectively, “
          <span className={classes.bold}>Third Party Content</span>”) and may be
          protected by applicable copyrights, trade-marks, patents, trade
          secrets or other proprietary rights and laws. Nothing in your use of
          Our Content or these Terms grants you any right, title or interest in
          or to this Third Party Content except for the limited right to use the
          Services as set out herein. In particular, the Services may provide
          access to applications or materials, including but not limited to the
          Buying, Selling of cryptocurrencies, cryptocurrency exchanges (swaps)
          that are hosted by parties other than CycurID™. You understand and
          agree that CycurID™ does not itself directly exchange virtual
          currencies and that all currency exchanges provided through the
          Software are provided by third parties. Accordingly, you understand it
          is impossible for CycurID™ to monitor Third Party Content and that you
          access such content and services at your own risk and it is your
          responsibility to review the third party’s terms and policies before
          using a Third Party Service.
          <br />
          <br />
          CYCURID ONLY PROVIDES THIRD PARTY CONTENT FOR THE SAKE OF CONVENIENCE
          AND IS NOT RESPONSIBLE FOR THE CONTENT, ACCURACY, SECURITY,
          AVAILABILITY, ANY PERFORMANCE, OR FAILURE TO PERFORM OF THE THIRD
          PARTY SERVICES OR ANY ISSUE IN RELATION WITH THE USE OF THIRD PARTY
          SERVICES INCLUDING BUT LIMITED TO PRICING, INFORMATION, EXCHANGE
          RATES, PROCESSING OF TRANSACTIONS, AND SIMILAR ACTIVITIES. WE DO NOT
          PROVIDE CUSTOMER SUPPORT FOR TRANSACTIONS PERFORMED ON THIRD-PARTY API
          PROVIDER’S SOFTWARE, SITE, OR SERVICES. CYCURID DOES NOT PROVIDE ANY
          GUARANTEES THAT ACCESS TO THIRD PARTY SERVICES WILL NOT BE INTERRUPTED
          OR THAT THERE WILL BE NO DELAYS, FAILURES, ERRORS, OMISSIONS,
          CORRUPTION OR LOSS OF TRANSMITTED INFORMATION, DATA OR FUNDS, AND
          CYCURID SHALL NOT BE LIABLE FOR ANY SUCH THIRD PARTY SERVICES. DO NOT
          SHARE ANY SENSITIVE INFORMATION, SUCH AS PRIVATE KEYS AND CREDENTIALS,
          WITH ANY THIRD PARTY WITHOUT FIRST INDEPENDENTLY VALIDATING THEIR
          LEGITIMACY.
          <br />
          <br />
          <span className={classes.bold}>Your Content</span> – The Services may
          contain bulletin board, chat, forum, social media, comments or other
          communication services. We do not claim ownership of any Content that
          you post, upload, input, provide, submit or otherwise transfer to us,
          or any third party, using the Services (collectively, “
          <span className={classes.bold}>Your Content</span>”); however, you
          agree that by posting, uploading, inputting, providing, submitting,
          entering or otherwise transmitting Your Content to us or any third
          party:
          <br />
          <br />
          &bull; you will be deemed to have granted us a royalty-free,
          non-exclusive, worldwide, fully paid-up, irrevocable license to use,
          copy, distribute, transmit, display, edit, delete, publish and
          translate Your Content to the extent reasonably required to provide
          the Services as they may exist from time to time, in any medium
          whatsoever, or to ensure adherence to, or enforce, these Terms;
          <br />
          <br />
          &bull; you will be deemed to have confirmed, represented and warranted
          to us that you have all right, title and interest, as well as the
          power and authority necessary, to grant such license to Your Content
          to us as set out above; and
          <br />
          <br />
          &bull; you will indemnify and save us and our parent, subsidiary and
          affiliated companies harmless from and against any liabilities,
          actions, proceedings, claims, causes of action, demands, debts,
          losses, damages, charges and costs, including reasonable legal costs,
          any amount paid to settle any action or to satisfy a judgment and
          expenses of any kind and character whatsoever incurred by us relating
          to or arising from Your Content, including instances where the Your
          Content (A) infringes any Third Party Content or other third-party
          intellectual property rights, or (B) is inappropriate, profane,
          defamatory, infringing, obscene, indecent or unlawful.
          <br />
          <br />
          <span className={classes.bold}>Deletion of Your Content</span> – You
          acknowledge and agree that we may retain a copy or copies of Your
          Content for archival or compliance purposes or to otherwise provide
          the Services to you or others, subject always to your license to us
          set out above, even if you delete your Account to which Your Content
          is connected. If permitted by the functionality of the Services, you
          may delete Your Content from the communication facilities thereupon,
          though we may retain a copy internally thereof for compliance
          purposes. We may also delete any of Your Content that we determine, in
          our sole discretion, violates these Terms.
          <br />
          <br />
          <span className={classes.bold}>Responsibility for Content</span> – You
          acknowledge and agree that you are exclusively responsible for
          determining the accuracy, suitability, harmfulness or legality of any
          content, information or material received, transmitted or sent by you
          using the Services.
          <br />
          <br />
          <span className={classes.bold}>
            Public Transmission and Caching
          </span>{' '}
          – You acknowledge and agree that the technical processing and
          transmission of the Services, including Your Content and other
          Content, may involve transmissions over various networks and changes
          to conform and adapt to technical requirements of connecting networks
          or devices, and that such Content may be subject to “caching” or other
          technical processing or transmission policies and procedures by us or
          at intermediate locations on the Internet.
          <br />
          <br />
          <span className={classes.bold}>Compliance and Complaints</span> – We
          do not have any obligation to censor or review any of Your Content, to
          censor or review any Third-Party Content or to monitor use of the
          Services. However, you agree that we may, without notice or liability,
          disclose to third parties any of your information or Your Content,
          monitor use of the Services, and monitor, review and retain any
          Content, including Your Content, if we believe in good faith that such
          activity is reasonably necessary to ensure adherence to or enforce
          these Terms, comply with any laws or regulations, respond to any
          allegation of illegal conduct or claimed violation of third party
          rights, or protect us or others. If we receive a complaint relating to
          use of the Services by you, you acknowledge and agree that we may, in
          our sole and absolute discretion and without notice or liability,
          investigate the complaint, and restrict, suspend or terminate your
          access to the Services, including your Account, and remove Your
          Content from our servers.
          <br />
          <br />
          <span className={classes.bold}>Complaints Handling Policy</span> –
          CycurID / imme is committed to providing the highest level of care to
          all our customers. If you feel that our service has not met your
          expectations, please tell us. Customer complaints are important to our
          organisation. They offer specific insights into how we might improve
          our services, processes and procedures.
          <br />
          <br />
          <span className={classes.bold}>
            What to do if you have a complaint?
          </span>{' '}
          - Please contact us at{' '}
          <a href='mailto:support@cycurid.com'>support@cycurid.com</a>,
          detailing the nature of your complaint and providing all relevant
          information and your contact details. To ensure that your complaint is
          resolved as soon as possible, please outline any steps you would like
          us to take in addressing the issue.
          <br />
          <br />
          <span className={classes.bold}>Our complaints procedure</span> - Once
          a complaint has been received, we will acknowledge it and aim to
          resolve it as quickly as possible. The length of time will depend on
          the nature of the issues involved. Should a delay occur, we will
          contact you explaining the reason of the delay and outline the next
          steps. If you have received an offer of remedial action or redress
          from us in response to a complaint you have submitted, and if you
          consider it to be acceptable, please let us know so that we can comply
          promptly with it.
          <br />
          <br />
          <span className={classes.bold}>Advertising</span> – We shall have the
          right, without notice, to insert advertising data into the Services,
          so long as this does not involve our transmission of any of your
          personal information in contravention of the Privacy Policy. If you
          elect to have any business dealings with any party whose products or
          services may be advertised on the Services, you acknowledge and agree
          that such dealings are solely between you and such advertiser and we
          will not be a party to, or have any responsibility or liability
          related thereto. You acknowledge and agree that no such advertising
          may be construed as an endorsement by us of any such products or
          services advertised.
          <br />
          <br />
          <span className={classes.bold}>Feedback</span> – All right, title and
          interest in and to comments, ideas, suggestions and impressions of the
          Services, and other products given by you to us (collectively, the “
          <span className={classes.bold}>Feedback</span>”) is and shall be
          deemed to be our property and, by submitting Feedback to us, you agree
          that you thereby assign to us all right, title and interest to such
          Feedback to us.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Proprietary Notices
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          <span className={classes.bold}>Copyright Notice</span> – The Services
          and all Content is owned and copyrighted by CycurID&trade; and/or its
          licensors, and is licensed to you in accordance with these Terms only.
          <br />
          <br />
          <span className={classes.bold}>Trademark Notice</span> – The
          trademarks, logos, and service marks displayed on or through the
          Services are the property (whether registered or unregistered) of
          CycurID&trade;, its licensors or other third parties. You are not
          permitted to use trademarks, logos and service marks for any purpose
          without our prior, written consent. Except as expressly indicated on
          the website, no endorsement, sponsorship, affiliation or other
          authorization is implied by any use of third party trademarks.
          <br />
          <br />
          <span className={classes.bold}>
            Infringer and Repeat Infringer Policy
          </span>{' '}
          – We have adopted a policy of terminating, in appropriate
          circumstances and at our sole discretion, users who are deemed to be
          repeat infringers of intellectual property. We may also, at our sole
          discretion, limit access to the Services, or terminate the Account of
          any user who infringes any intellectual property rights of us or
          others, whether or not there is any repeat infringement.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Acceptable Use and Prohibitions
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          In addition to any other terms or conditions regarding your use of the
          Services in these Terms, we may require you to agree to specific terms
          for particular services, products or areas of the Services from time
          to time by confirming your agreement. Any such additional terms which
          you agree to will be deemed incorporated into these Terms.
          <br />
          <br />
          <span className={classes.bold}>
            Proper Conduct (Things You Must Do)
          </span>{' '}
          – Without limiting the generality of anything else contained in these
          Terms, you must ensure that:
          <br />
          <br />
          &bull; you only use the Services for lawful purposes; and
          <br />
          <br />
          &bull; if at any time you become aware of any violation, by any person
          or entity under your control, of any part of these Terms, you will
          immediately notify us and provide us with assistance, as requested, to
          stop or remedy such violation
          <br />
          <br />
          <span className={classes.bold}>
            Prohibited Conduct (Things You Must Not Do)
          </span>{' '}
          – Without limiting the generality of anything else contained in these
          Terms, you agree that you will not, in connection with the Services,
          directly or indirectly do or permit any of the following:
          <br />
          <p>
            (i) post, upload, reproduce, distribute or otherwise transmit any
            Content that:
          </p>
          <p>
            (A) is unauthorized or unsolicited commercial communications, junk
            or bulk communications or other “spam” (whether or not using e-mail
            services, including instant messaging, blog or comment spam) or is
            otherwise duplicative or unsolicited,
          </p>
          <p>
            (B) contains a virus, cancelbot, Trojan horse, worm or other
            harmful, disruptive or surreptitious component,
          </p>
          <p>(C) is defamatory, infringing, or unlawful,</p>
          <p>
            (D) is inappropriate, profane, obscene, or indecent or information
            without suitable or lawfully-required access controls (which
            controls shall in no event be our responsibility),
          </p>
          <p>
            (E) gives rise to civil liability, or otherwise violates the rights
            or assists others to violate the rights of us or any third party,
            such violations including engaging in copyright infringement,
            invasion of privacy, trademark infringement or defamation,
          </p>
          <p>
            (F) constitutes a criminal offence, or otherwise engages in or
            assists others to engage in any criminal offence, including pyramid
            selling, unauthorized use of a computer, mischief in relation to
            data, fraud, obscenity and child pornography; or
          </p>
          <p>
            (G) incites discrimination, hate or violence towards one person or a
            group because of their belonging to a race, religion, nationality,
            sexuality, gender or other human rights-protected group of persons;
          </p>
          <p>
            (ii) engage in threats, harassment, intimidation, stalking or abuse
            or any conduct that violates the legal rights of others, including
            the rights of minors and rights relating to privacy and publicity;
          </p>
          <p>
            (iii) scan or probe another computer system, obstruct or bypass
            computer identification procedures or engage in unauthorized
            computer or network trespass without the express permission of the
            owners of such computer systems
          </p>
          <p>
            (iv) forge headers or otherwise manipulate any protocols or
            identifiers used in any system or protocol in such a manner to
            disguise the origin of any data transmitted using the Services
          </p>
          <p>
            (v) impersonate or falsely represent your association with any
            person, including a representative of us
          </p>
          <p>
            (vi) disrupt or threaten the integrity, operation or security of any
            service, computer or any Internet system
          </p>
          <p>
            (vii) remove, disable or circumvent any access control, copy
            protection, rights management, security feature or related process
            or procedure established with respect to the Services
          </p>
          <p>
            (viii) rent, lease, sell, lend, assign, publish, transfer,
            sublicense, share, resell, reproduce, copy, distribute,
            redistribute, or exploit for any purposes (except for your internal,
            personal, non-commercial purposes), any portion of, use of or access
            to, all of or any feature or part of the Services, except where
            expressly authorized by us
          </p>
          <p>
            (ix) modify, translate, adapt or otherwise create derivate works or
            improvements, whether or not patentable, of the Services
          </p>
          <p>
            (x) harvest, scrape, extract, gather, collect, or store personal
            information about others without their express consent; or
          </p>
          <p>
            (xi) harvest, scrape, or use any robot, spider, crawler, script or
            other automated means or interface not provided by us to access the
            Services or to extract data, collect information or otherwise
            interact with the Services.
          </p>
        </Typography>
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Termination
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          We may, in our sole discretion, suspend, restrict or terminate your
          use of the Services, including your Account, and Your Content,
          effective at any time, without notice to you, for any reason,
          including if the operation or efficiency of Services, or our or any
          third party’s equipment or network is impaired by your use of the
          Services, we have received a third party complaint which relates to
          your use or misuse of our Services, or you have been or are in breach
          of any term or condition of these Terms.
          <br />
          <br />
          You acknowledge and agree that termination, curtailment, or suspension
          of these Terms for any reason may result in restrictions of,
          disruptions to or cessation of your or third party access to your
          Account and Your Content, and you hereby agree to release us from any
          and all liability and claims of loss resulting from restrictions,
          disruptions or cessations. If your use of the Services is suspended or
          restricted, we will have no obligation to forward any of Your Content
          to you or any third party.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Disclaimers, Limits of Liability and Indemnities
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          <span className={classes.bold}>Limitations</span> – Your use of the
          Services depends on mobile or wireless networks, including the
          internet, telecommunications networks, cabling, facilities and
          equipment that is not in our control; accordingly
          <br />
          <br />
          (i) we cannot guarantee any minimum level regarding performance,
          speed, reliability, availability, use or consistency, and
          <br />
          <br />
          (ii) data, messages, information or materials sent over the internet
          may not be completely private, and your anonymity is not guaranteed.
          <br />
          <br />
          <span className={classes.bold}>Customer Acknowledgement</span> – You
          acknowledge and agree that
          <br />
          <br />
          (i) all use of the Services provided by us is at your own risk, and
          <br />
          <br />
          (ii) the Content that you may access while using the Services may
          contain links to websites or third party applications which are
          provided solely as a convenience to you and the inclusion of any such
          links do not imply endorsement, investigation or verification by us of
          such websites or third party applications or the information contained
          therein.
          <br />
          <br />
          <span className={classes.bold}>Disclaimer of Warranties</span> – ALL
          PRODUCTS AND SERVICES PROVIDED UNDER THESE TERMS, INCLUDING WITHOUT
          LIMITATION THE SERVICES AND THE SITE, ARE PROVIDED ON AN “AS IS” AND
          “AS AVAILABLE” BASIS, WITH ALL FAULTS AND WE EXPRESSLY DISCLAIM AND
          MAKE NO CONDITIONS, WARRANTIES OR REPRESENTATIONS ABOUT THE
          SUITABILITY, RELIABILITY, USABILITY, SECURITY, QUALITY, CAPACITY,
          PERFORMANCE, AVAILABILITY, TIMELINESS OR ACCURACY OF THE SERVICES OR
          SITE OR IN ANY WAY RELATED TO THESE TERMS AND YOUR USE OF THE SITE OR
          SERVICES. WE FURTHER EXPRESSLY DISCLAIM ALL CONDITIONS, WARRANTIES AND
          REPRESENTATIONS, EXPRESS, IMPLIED OR STATUTORY, INCLUDING IMPLIED
          CONDITIONS OR WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, DURABILITY, USABILITY, TITLE AND NON-INFRINGEMENT, WHETHER
          ARISING BY USAGE OF TRADE, BY COURSE OF DEALING, BY COURSE OF
          PERFORMANCE, AT LAW, IN EQUITY, BY STATUTE OR OTHERWISE HOWSOEVER. YOU
          ACKNOWLEDGE AND AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW,
          ALL RISK OF USE OF THE SITE OR THE SERVICES IS ENTIRELY YOUR
          RESPONSIBILITY.
          <br />
          YOU ACKNOWLEDGE AND AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE
          LAW, ALL RISK OF USE OF THE SITE OR THE SERVICES IS ENTIRELY YOUR
          RESPONSIBILITY.
          <br />
          <br />
          <span className={classes.bold}>No Liability</span> – Notwithstanding
          any other provision of these Terms, in no event will CycurID&trade;,
          our subsidiaries, our affiliates, or our controlling parties,
          directors, officers, agents employees, suppliers, licensors, resellers
          or distributors be liable for any direct, indirect, special,
          incidental, consequential or punitive damages, or any other damages or
          losses whatsoever, including damages for loss of profits, goodwill,
          opportunity, earnings, use or data, arising directly or indirectly
          from or related to these Terms or the Services or Site, or any Content
          or software in connection therewith, regardless of the cause of action
          and even if one or more of us have been advised of the possibility of
          such damages or losses, or if such damages or losses would be
          reasonably foreseeable, including damages or losses arising from or in
          any way related to the following:
          <br />
          <br />
          &bull; sending, receiving, not sending, not receiving, loss, deletion
          or alteration of any transmissions, data or transactions entered into
          through or using the Services
          <br />
          <br />
          &bull; any suspension, curtailment, restriction, termination or other
          limitation placed on your use of the Services, or your Account
          <br />
          <br />
          &bull; any act or omission of you or any third party, including any
          threatening, defamatory, obscene, offensive or illegal conduct or any
          infringement of another’s rights, including intellectual property
          rights
          <br />
          <br />
          &bull; any loss or damage to Your Content or other data arising
          directly or indirectly from your use or non-use of the Services or
          related components
          <br />
          <br />
          &bull; the performance of the Internet or the Services
          <br />
          <br />
          &bull; the content or accuracy of any material, information or data
          (including any software) related to these Terms or viewed, downloaded,
          accessed or transmitted using, over or through the Internet or the
          Services, including material that infringes the rights of others or
          otherwise violates laws or regulations; and
          <br />
          <br />
          &bull; delays, errors, interruptions, mistakes, omissions,
          non-delivery, incorrect delivery, viruses, Trojan horses, spyware,
          spam or defects in the transmission of any information, material or
          data over or through our systems or networks or the systems or
          networks of third parties.
          <br />
          <br />
          To the extent that a province or jurisdiction does not permit the
          exclusion or limitation of liability as set forth in this paragraph,
          our liability is limited to the maximum extent permitted by the laws
          in such province, state or jurisdiction.
          <br />
          <br />
          <span className={classes.bold}>Cap on Liability</span> – Under no
          circumstances will CycurID&trade;, our subsidiaries, our affiliates,
          or our controlling parties, directors, officers, agents employees,
          suppliers, licensors, resellers or distributors be labile to you for
          damages arising out of the Services, your use of the Site, or these
          Terms generally in any amount exceeding $1000 CDN.
          <br />
          <br />
          <span className={classes.bold}>Applicability</span> – Some
          jurisdictions prohibit the disclaimer of certain warranties or
          conditions or the limitation of certain types of liability. In such
          circumstances, to the extent that such prohibitions prohibit any
          exclusions and limitations in these Terms, such exclusions and
          limitations will not apply to you strictly to the extent necessary to
          make these Terms consistent with such prohibitions.
          <br />
          <br />
          <span className={classes.bold}>Indemnity By You</span> – You agree to
          indemnify and hold us harmless from all liabilities, actions,
          proceedings, claims, causes of action, demands, debts, losses,
          damages, charges and costs, including reasonable legal costs, any
          amount paid to settle any such action, or to satisfy a judgment, and
          expenses of any kind and character whatsoever incurred by us relating
          to or arising from
          <br />
          <br />
          (i) access to or use, by you or permitted by you, of the Services or
          your Account, or
          <br />
          <br />
          (ii) any of your acts or omissions, including breach or
          non-performance of these Terms and any violation of any third party
          rights.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          General Provisions
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          <span className={classes.bold}>Interpretation</span> – In these Terms,
          <br />
          <br />
          (i) the captions and headings are for convenience only and do not
          constitute substantive matter and are not to be construed as
          interpreting the contents of these Terms,
          <br />
          <br />
          (ii) the word “<span className={classes.bold}>including</span>”, the
          word “<span className={classes.bold}>includes</span>” and the phrase “
          <span className={classes.bold}>such as</span>”, when following a
          general statement or term (whether or not non-limiting language such
          as “without limitation” or “but not limited to” or other words of
          similar import are used with reference thereto), is not to be
          construed as limiting, and the word “
          <span className={classes.bold}>or</span>” between two or more listed
          matters does not imply an exclusive relationship between the matters
          being connected, and
          <br />
          <br />
          (iii) all references to website addresses or URLs shall also include
          any successor or replacement websites containing substantially similar
          information as the referenced website(s).
          <br />
          <br />
          <span className={classes.bold}>Waiver of Rights and Remedies</span> –
          Our failure to insist upon or enforce strict performance of any
          provision of these Terms will not be construed as a waiver of any
          provision or right. Neither the course of conduct between you and us
          nor trade practice shall act to modify any provision of these Terms.
          Our rights, powers and remedies in these Terms, including without
          limitation the right to suspend, restrict or terminate your access to
          any portion of the Services, are cumulative and in addition to and not
          in substitution for any right, power or remedy that may be available
          to us at law or in equity.
          <br />
          <br />
          <span className={classes.bold}>Severability</span> – If any provision
          of these Terms is determined to be invalid or unenforceable in whole
          or in part, such invalidity or unenforceability will attach only to
          such provision or part thereof and the remaining part of such
          provision and all other provisions hereof will continue in full force
          and effect.
          <br />
          <br />
          <span className={classes.bold}>Notifications</span> – Subject to our
          Privacy Policy, we may provide you with notifications via email, in
          hard copy, through your Account, or through conspicuous posting of
          such notice on the Services, as we may determine in our sole
          discretion.
          <br />
          <br />
          <span className={classes.bold}>Governing Law and Jurisdiction</span> –
          These Terms and the rights of the parties hereto are governed by, and
          will at all times be construed in accordance with, the laws in force
          in the Province of British Columbia and the laws of Canada applicable
          therein, without reference to its conflict of laws principles. You
          hereby irrevocably consent to the jurisdiction of the courts of the
          Province of British Columbia in connection with any matter arising out
          of or in connection with these Terms.
          <br />
          <br />
          <span className={classes.bold}>Void Where Prohibited</span> -
          CycurID&trade; administers and operates the business functions,
          technology and Site from their head office in Vancouver, British
          Columbia, Canada. However, other CycurID&trade; applications and
          services may be administered and operated from various locations
          within or outside Canada. Servers are located in Montreal Quebec,
          Canada. Although the Site may be accessible worldwide, not all
          features, products or services discussed, referenced, provided or
          offered through or on the Site are available to all persons or in all
          geographic locations, or appropriate or available for use outside
          Canada. CycurID&trade; reserves the right to limit, in its sole
          discretion, the provision and quantity of any feature, product or
          service to any person or geographic area. Any offer for any feature,
          product or service made on the Site is void where prohibited. If you
          choose to access the Site from outside Canada, you do so on your own
          initiative and you are solely responsible for complying with
          applicable local laws.
          <br />
          <br />
          <span className={classes.bold}>
            Limitation on time to file claims
          </span>{' '}
          - ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THE SERVICES MUST BE COMMENCED WITHIN TWO (2) YEARS AFTER THE CAUSE
          OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
          PERMANENTLY BARRED.
          <br />
          <br />
          <span className={classes.bold}>Assignment and Inurement</span> – We
          may at any time assign our rights and obligations under these Terms,
          in whole or in part, without notice to you. You may not assign these
          Terms without our prior, written consent. These Terms will inure to
          the benefit of and bind you and us and our respective personal and
          legal representatives, successors and permitted assigns.
          <br />
          <br />
          <span className={classes.bold}>Survival</span> – All provisions that,
          by their meaning or nature, are intended to survive termination or
          expiry of these Terms shall survive termination or expiration of these
          Terms.
          <br />
          <br />
          <span className={classes.bold}>Relationship</span> – You agree that no
          joint venture, partnership, employment or agency relationship exists
          between us and you as a result of these Terms or your use of the
          Services.
          <br />
          <br />
          <span className={classes.bold}>Entire Agreement</span> – These Terms,
          as amended from time to time, including any and all documents,
          websites, rules, terms and policies referenced herein, constitutes the
          entire agreement between us and you with respect to the matters
          referred to in these Terms and supersedes all prior and
          contemporaneous agreements and understandings, whether electronic,
          oral or written, between us and you with respect to such matters.
          <br />
          <br />
          <span className={classes.bold}>English Language</span> – The parties
          have requested and agree that this agreement and all documents
          relating thereto be drawn up in English / Les parties ont demandé que
          cette convention ainsi que tous les documents qui s’y rattachent
          soient rédigés en anglais.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          Questions and Concerns:
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          If you have any questions or concerns about these Terms, please
          contact us at{' '}
          <a href='mailto:support@cycurid.com'>support@cycurid.com</a> or (800)
          310-3992.
        </Typography>
        <br />
        <br />
        Copyright © CycurID 2023. All rights reserved.
        <br />
        <br />
        <br />
        <br />
      </main>
      <ContactUs />
    </>
  )
}
